import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorMessage.module.scss';
import Images from './../../assets/Images';

class ErrorMessage extends Component {

  render() {
    const { errors } = this.props;

    if (!errors || errors.length === 0) {
      return null;
    }
    else {
      return (
        <div className={styles.errorMessage}>
          <img className={styles.errorIcon} src={Images.error} alt='error'></img>
          <div className={styles.error}>
            <div className={styles.errorTitle}>
              {this.props.title}
            </div>
            <div className={styles.errorList}>
              {this.renderErrors()}
            </div>
          </div>
        </div>
      );
    }
  }

  renderErrors() {
    const { errors } = this.props;
  
    if (errors.isArray) {
      errors.map(function(e, i) {
        return (<span key={i} className={styles.errorText}>{e}</span>);});
    }
    else {
      var e = JSON.stringify(errors);
      return (<span className={styles.errorText}>{e}</span>);
    }
  }
}

ErrorMessage.propTypes = {
  title: PropTypes.string.isRequired,
  errors: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
};

ErrorMessage.defaultProps = {
  title: '',
  errors: []
};

export default ErrorMessage; 