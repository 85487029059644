import * as constants from '../constants/actions';
import test from '../constants/test';

const initialState = {
  isFetching: true,
  error: undefined,
  answers: {},
  results: {},
  score: {},
  favoredTest: undefined,
  otherTest: undefined,
  stronglyFavored: undefined
};

function calcFavoredTest(score) {
  if (score['SAT'] > score['ACT'])
    return 'SAT';
  if (score['ACT'] > score['SAT'])
    return 'ACT';
  if (score['ACT'] === score['SAT']) {
    if(score.breakdown['ACT']['Speed'] > 0)
      return 'ACT';
    else 
      return 'SAT';
  }
}

function gradeAnswers(answers, results) {
  for(let sectionId in answers) {
    let sectionKey = test.find((s) => s.sectionIndex === parseInt(sectionId));
    for(let questionId in answers[sectionId]) {
      if(questionId === 'timeLeft') {
        if(parseInt(sectionId) === 1) { //Reading
          results['readingTime'] = answers[sectionId][questionId] >= 75;
        }
        if(parseInt(sectionId) === 2) { //Math calc
          results['mathCalcTime'] = answers[sectionId][questionId] >= 60;
        }
        if(parseInt(sectionId) === 3) { //Math no calc
          results['mathNoCalcTime'] = answers[sectionId][questionId] >= 60;
        }
      }
      else {
        let answerKey = sectionKey.questions.find((q) => q.id === parseInt(questionId));
        results[questionId] = answers[sectionId][questionId] === answerKey.correctAnswer;
      }
    }
  }
}

function gradeResults(results) {
  let score = {
    SAT: 0, ACT: 0,
    breakdown: {
      SAT: {
        'Reading': 0,
        'Math no Calculator': 0,
        'Math with Calculator': 0,
        'Word Meaning': 0,
        'Citing Evidence': 0,
        'Line Reference': 0,
      },
      ACT: {
        'Reading': 0,
        'Math with Calculator': 0,
        'Science': 0,
        'Speed': {
          'Math no Calculator': false,
          'Math with Calculator': false,
          'Reading': false
        }
      },
      questionsCorrect: 0
    }
  };
  let SAT_IDS = [53101, 53102, 53104, 53106, 53109, 53110, 53111, 53112, 53113, 53115, 53117];
  let ACT_IDS = [53100, 53103, 53105, 53107, 53108, 53114, 53116, 53118];
  let SAT_BREAKDOWNS = {
    'Reading': [53101, 53102, 53104, 53106, 53109],
    'Math no Calculator': [53110, 53111, 53112],
    'Math with Calculator': [53113, 53115, 53117],
    'Word Meaning': [53101, 53102],
    'Citing Evidence': [53104, 53106],
    'Line Reference': [53109],
  };
  let ACT_BREAKDOWNS = {
    'Reading': [53100, 53103, 53105, 53107, 53108],
    'Math with Calculator': [53114, 53116, 53118],
  };
  let section_times = ['readingTime', 'mathNoCalcTime', 'mathCalcTime'];
  let science_ids = [53130, 53131, 53132, 53133, 53134 ];

  for(let s_id of SAT_IDS) {
    if(results[s_id]) {
      score['SAT'] += 1;
      addBreakdown(SAT_BREAKDOWNS, s_id, score['breakdown']['SAT']);
      score['breakdown']['questionsCorrect'] += 1;
    }
  }
  if(score['SAT'] >= 8) {
    score['SAT'] += 1;
  }
  for(let a_id of ACT_IDS) {
    if(results[a_id]) {
      score['ACT'] += 1;
      addBreakdown(ACT_BREAKDOWNS, a_id, score['breakdown']['ACT']);
      score['breakdown']['questionsCorrect'] += 1;
    }
  }

  let science_score = 0;
  science_ids.forEach(function(id) { if(results[id]) {science_score += 1;score['breakdown']['questionsCorrect'] += 1;score['breakdown']['ACT']['Science'] += 1;}});
  if (science_score > 2) {
    score['ACT'] += 1;
  }

  for(let s_t of section_times) {
    if(results[s_t]) {
      if(s_t === 'readingTime') {
        if(score['breakdown']['ACT']['Reading'] > 5) {
          score['ACT'] += 1;
          score['breakdown']['ACT']['Speed']['Reading'] = true;
        }
      }
      if(s_t === 'mathNoCalcTime') {
        if(score['breakdown']['SAT']['Math no Calculator'] > 1) {
          score['ACT'] += 1;
          score['breakdown']['ACT']['Speed']['Math no Calculator'] = true;
        }
      }
      if(s_t === 'mathCalcTime') {
        let math_score = score['breakdown']['SAT']['Math with Calculator'] + score['breakdown']['ACT']['Math with Calculator'];
        if(math_score > 3) {
          score['ACT'] += 1;
          score['breakdown']['ACT']['Speed']['Math with Calculator'] = true;
        }
      }
    }
  }
  
  return score;
}

function addBreakdown(breakdown, id, score) {
  for(let[key, value] of Object.entries(breakdown)) {
    if(value.includes(id)) {
      score[key] += 1;
    }
  }
}

function getOtherTest(favoredTest) {
  return (favoredTest === 'ACT') ? 'SAT' : 'ACT';
}

function stronglyFavored(actScore, satScore) {
  return Math.abs(actScore - satScore) > 3;
}

export default function testsession(state = initialState, action) {
  switch (action.type) {
      
    case constants.GRADE_TEST: 
      var gAnswers = action.answers;
      var gResults = state.results;
      gradeAnswers(gAnswers, gResults);
      var gScore = gradeResults(gResults);
      var gFavored = calcFavoredTest(gScore)
      
      localStorage.clear();
      
      return Object.assign({}, state, {
        answers: gAnswers,
        results: gResults,
        score: gScore,
        favoredTest: gFavored,
        otherTest: getOtherTest(gFavored),
        stronglyFavored: stronglyFavored(gScore['ACT'], gScore['SAT'])
      });

    case constants.LOAD_TEST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        error: undefined,
      });

    case constants.LOAD_TEST_SUCCESS:
      var lAnswers = action.answers;
      var lResults = state.results;
      gradeAnswers(lAnswers, lResults);
      var lScore = gradeResults(lResults);
      var lFavored = calcFavoredTest(lScore)

      return Object.assign({}, state, {
        isFetching: false,
        error: undefined,

        answers: lAnswers,
        results: lResults,
        score: lScore,
        favoredTest: lFavored,
        otherTest: getOtherTest(lFavored),
        stronglyFavored: stronglyFavored(lScore['ACT'], lScore['SAT'])
      });

    case constants.LOAD_TEST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
      });

    default:
      return state;
  }
}