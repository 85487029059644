import React from 'react';
import styles from './../components/Passage/Passage.module.scss';

const passageBody = 
    <div className={styles.passageBody}>
      <div className={styles.para}>
        John Muir told us what we can expect when humans with their<br/> 
        habits begin to unravel a sound ecosystem. "When we try to<br/>
        pick out anything by itself, we find it hitched to everything<br/> 
        else in the universe," he wrote. In the mangroves off the<br/>
        <div className={styles.lineNum}>5</div>
        east coast of Central America, at the edge of the<br/>
        Mesoamerican Reef, the world appears to be divided into two:<br/>
        the above and the below. As we killed the engines and poled<br/>
        the skiff from the hot April sun into the shade of the<br/>
        forest, Will Heyman, my marine biologist companion, and I<br/>
        <div className={styles.lineNum}>10</div>
        gazed into the simplicity above. We saw the green crowns of<br/>
        one of the least diverse of all tropical forests, where there<br/>
        is often but a single species of tree, the red mangrove.
      </div>
      <div className={styles.para}>
        Salinity, storm waves, and oxygen-poor mud discourage<br/>
        understory growth in the mangroves, so there was little<br/>
        <div className={styles.lineNum}>15</div>
        beneath the canopy for us to see: the occasional orchid, a<br/>
        vine, a troop of fiddler crabs guarding holes in the mud. I<br/>
        leaned over the gunwale to sample the mud around the roots,<br/>
        scooping up sherds of pottery. I contemplated slipping an<br/>
        ancient Mayan souvenir into my pocket -- with such a lode<br/>
        <div className={styles.lineNum}>20</div>
        here, what possible harm? "Strictly catch-and-release,"<br/> 
        Heyman said. With a splashing of jettisoned sherds, we poled<br/>
        to another spot. There, in the still water, we witnessed the<br/>
        baffingly complex miracle of the below. At the waterline the<br/>
        roots in this forest blossom downward, expanding<br/>
        <div className={styles.lineNum}>25</div>
        shaggy-bearded with mats of algae and slender brittle stars,<br/>
        dotted with little vases of filter feeders in orange and<br/> 
        purple, all nestled among soft corals in still more hues:<br/> 
        nothing unadorned.<br/>
      </div>
      <div className={styles.para}>
        Mangroves are crucial nurseries where schools of translucent<br/>
        <div className={styles.lineNum}>30</div>
        small fry mature among the Moorish architecture of their<br/>
        arched roots. The palest clouds of them are hardly there at<br/>
        all, composed of hatchlings no bigger than the smallest<br/>
        mosquito wigglers, living motes practically too small to<br/>
        identify. Are they destined for adulthood in the reef or the<br/>
        <div className={styles.lineNum}>35</div>
        open ocean? Too soon to tell, but they’ll be safe for now.<br/>
      </div>
      <div className={styles.para}>
        Mesoamerican mangroves form multiple lines of defense for<br/>
        the reef system. The first line is the tall mangrove forest<br/>
        along the coast and up the mouths of tidal rivers. The second<br/>
        line, and sometimes a third and fourth, occur offshore, in<br/>
        <div className={styles.lineNum}>40</div>
        places where pointy mangrove seedlings have taken root atop a<br/>
        series of shallow marine ridges. Each clump slowly gathers<br/>
        the makings of an islet under itself. These islets grow into<br/>
        islands -- mangrove cays -- arranged in linear archipelagoes.<br/>
        The clusters of cays work as screens, aiding the sea grass by<br/>
        <div className={styles.lineNum}>45</div>
        moderating wave action and the coral reef by intercepting<br/>
        silt, fertilizers, and toxins in runoff from land.<br/>
      </div>
      <div className={styles.para}>
        The mangroves, in addition to defense, provide mulch. They<br/>
        can shed tons of leaves per acre every year. Fungi and<br/>
        bacteria break down this leaf litter and consume it, then are<br/>
        <div className={styles.lineNum}>50</div>
        consumed by tiny worms and crustaceans, which in turn feed<br/>
        small fish, which feed larger fish and birds and crocodiles.<br/>
        Life wells outward from the mangroves into the sea. At the<br/>
        same time, a living countercurrent flows back in: the eggs,<br/>
        larvae, and sometimes the gravid females of reef creatures<br/>
        <div className={styles.lineNum}>55</div>
        that use the mangroves as a nursery.<br/> 
      </div>
      <div className={styles.para}>
        If any fish is emblematic of this life cycle -- kindergarten<br/>
        in the mangroves, graduate school on the reef -- it is the<br/>
        rainbow parrotfish, whose scientific name, Scarus guacamaia,<br/>
        from the native Taino huacamayo, means "macaw." The<br/> 
        <div className={styles.lineNum}>60</div>
        resemblance is eerie: The fish has the beak and<br/>
        blue-and-yellow coloration of the tropical bird. The<br/> 
        parrot-fish starts small in the mangroves, as drab as a<br/>
        sparrow, and ends in full color on the reef, four feet long,<br/>
        the largest herbivorous fish in the Atlantic.<br/>
      </div>
      <div className={styles.para}>
        <div className={styles.lineNum}>65</div>
        The trees are more than just a convenience for Scarus<br/>
        guacamaia. When mangroves are carved away, to make room for<br/> 
        tourist venues, for example, the species tends to go locally<br/>
        extinct, with repercussions in all directions. I was amazed<br/>
        to learn how coevolution has brought the mangroves, the<br/>
        <div className={styles.lineNum}>70</div> 
        coral, and the parrotfish all into balance. When the<br/> 
        horny-beaked herbivores are fished out or otherwise<br/> 
        eliminated, the reef’s delicate corals become overgrown by<br/> 
        carpets of the algae the parrotfish normally eat. I thought<br/>
        the world of the Mesoamerican Reef was starkly divided by the<br/>
        <div className={styles.lineNum}>75</div>
        waterline, but the parrotfish suggest otherwise. In this<br/>
        little section of the universe, my guide showed me that Mr.<br/>
        Muir’s hitches are tied particularly tight.<br/>
      </div>
    </div>;

export default passageBody;




