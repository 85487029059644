import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
  updateSessionState,
  chooseAnswer, 
  pauseSession, 
  completeTestSection,
  toggleInstructionModal } from '../../actions';
import PassageTest from './../PassageTest';
import MathTest from '../MathTest';
import TestHeader from '../TestHeader';
import styles from './TestSession.module.scss';
import TestSectionDirections from '../TestSectionDirections';

class TestSession extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      currentTime: 0
    };
  }

  getTestSectionRemainingTime() {
    const { section, timeSaved } = this.props;
    return timeSaved ? timeSaved : section.time;
  }

  getTotalSectionMinutes() {
    const { section, extendedTime } = this.props;
    if(extendedTime) {
      return Math.floor(section.extendedTime/60);
    }
    else {
      return Math.floor(section.time/60);
    }
  }

  completeSection = () => {
    if(this.props.section.sectionIndex === 4) {
      // this.props.history.push('/results');
      this.props.history.push('/complete');
    }
    this.props.completeTestSection(this.state.currentTime);
  }

  currentQuestionChange = (questionIndex) => {
    this.props.updateSessionState(this.state.currentTime, questionIndex);
  }
  
  pauseSession = () => {
    this.props.pauseSession(this.state.currentTime);
    this.props.history.replace('/pause');
  }

  updateTime = (time) => {
    this.setState({
      currentTime: time
    });
  }

  answerQuestion = (sectionIndex, questionID, answerID) => {
    this.props.chooseAnswer(sectionIndex, questionID, answerID, this.state.currentTime);
  }

  renderTestSectionDirections() {
    const { section, toggleInstructionModal } = this.props;

    return (
      <TestSectionDirections
        testTitle={section.subject}
        totalTime={this.getTotalSectionMinutes()}
        totalQuestions={section.questions.length}
        onDismiss={toggleInstructionModal}
      ></TestSectionDirections>
    );
  }

  render() {

    const { section, 
      timeSaved, 
      currentQuestion, 
      completedQuestions, 
      showInstructionModal,
      extendedTime } = this.props;
    const completedSectionQuestions = completedQuestions[section.sectionIndex];
  
    const directionsModal = showInstructionModal 
    ? this.renderTestSectionDirections() 
    : null;

    let testSection;
    if (section.subject === 'Reading' || section.subject === 'Science' )
      testSection = <PassageTest 
        section={section} 
        currentQuestionChange={this.currentQuestionChange}
        chooseAnswer={this.answerQuestion}
        currentQuestion={currentQuestion}
        completedQuestions={completedSectionQuestions}
        completeSection={this.completeSection}
      />;
    else
      testSection = <MathTest 
        section={section} 
        chooseAnswer={this.answerQuestion}
        completedQuestions={completedSectionQuestions}
        completeSection={this.completeSection}
      />;

    return (
      <div className={styles.testSession}>
        <TestHeader 
          extendedTime={extendedTime}
          section={section}
          timeSaved={timeSaved}
          pauseSession={this.pauseSession}
          completeSection={this.completeSection}
          updateTime={this.updateTime}
          showTimer={!showInstructionModal} />
        {testSection}
        {directionsModal}
      </div>
    );
  }
}

TestSession.propTypes = {
  section: PropTypes.object,
  chooseAnswer: PropTypes.func.isRequired,
  updateSessionState: PropTypes.func.isRequired,
  pauseSession: PropTypes.func.isRequired,
  completeTestSection: PropTypes.func.isRequired,
  timeSaved: PropTypes.number,
  currentQuestion: PropTypes.number.isRequired,
  history: PropTypes.object,
  completedQuestions: PropTypes.object.isRequired,
  showInstructionModal: PropTypes.bool.isRequired,
  toggleInstructionModal: PropTypes.func.isRequired,
  extendedTime: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    timeSaved: state.testsession.timeSaved,
    currentQuestion: state.testsession.currentQuestion,
    completedQuestions: state.testsession.testAnswers,
    showInstructionModal: state.testsession.showInstructionModal,
    extendedTime: state.testsession.extendedTime
  };
}

function mapDispatchToProps(dispatch) {
  return {
    chooseAnswer: (sectionId, question, answer, timeSaved) => dispatch(chooseAnswer(sectionId, question, answer, timeSaved)),
    pauseSession: (timeSaved) => dispatch(pauseSession(timeSaved)),
    completeTestSection: (testAnswers) => dispatch(completeTestSection(testAnswers)),
    updateSessionState: (time, questionID) => dispatch(updateSessionState(time, questionID)),
    toggleInstructionModal: (request) => dispatch(toggleInstructionModal(request)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestSession); 