import fetch from 'isomorphic-fetch';
import { EventTypes } from 'redux-segment';

import { 
  USER_VERIFY_REQUEST, 
  USER_VERIFY_SUCCESS, 
  USER_VERIFY_FAILURE } from '../constants/actions';

function userVerifyRequest() {
  return {
    type: USER_VERIFY_REQUEST
  };
}

function userVerifySuccess(json) {
  let analytics = {
    eventType: EventTypes.identify,
    eventPayload: {
      userId: json.data.email,
    }
  };

  return {
    type: USER_VERIFY_SUCCESS,
    receivedAt: Date.now(),
    data: json.data,
    meta: {
      analytics
    }
  };
}

function userVerifyFailure(error) {
  return {
    type: USER_VERIFY_FAILURE,
    error: error,
    failureAt: Date.now()
  };
}

export function verifyUser(form) {
  return function (dispatch) {
    dispatch(userVerifyRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let formData = [];
    for (var key in form) {
      if (form.hasOwnProperty(key)) {
        formData.push(encodeURIComponent(key) + '=' + encodeURIComponent(form[key]));
      }
    }
  
    return fetch('https://app.testive.com/api/a/student_app_confirm_account/', {
      method: 'POST',
      body: formData.join('&'),
      headers: header
    }).then(
      response => response.json(),
      error => dispatch(userVerifyFailure(error.message))
    )
      .then(
        function(json) {
          if (json.status === 'ok') {
            dispatch(userVerifySuccess(json));
          } else if(json.status === 'error'){
            dispatch(userVerifyFailure(json.msg));
          }
        }
      );
  };
}
