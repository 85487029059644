import React from 'react';
import PropTypes from 'prop-types';

import Question from '../Question';
import GridinQuestion from '../GridinQuestion';
import Button from '../Button';

import styles from './MathTest.module.scss';

class MathTest extends React.Component {

  chooseAnswer = (questionID, answerID) => {
    this.props.chooseAnswer(this.props.section.sectionIndex, questionID, answerID, 0);
  }

  render() {
    const { section, completedQuestions, completeSection } = this.props;

    return (
      <div className={styles.mathTest}>
        <div className={styles.questionContainer}>
          {section.questions.map((q, i) => {
            if (q.gridin) {
              return (
                <GridinQuestion
                  key={i} 
                  questionNumber={i+1}
                  questionID={q.id}
                  questionBody={q.body}
                  answerOptions={q.answers}
                  chooseAnswer={this.chooseAnswer}
                  selectedAnswer={completedQuestions[q.id]}/>);
            }
            else {
              return (
                <Question 
                  key={i} 
                  questionNumber={i+1}
                  questionID={q.id}
                  questionBody={q.body}
                  answerOptions={q.answers}
                  chooseAnswer={this.chooseAnswer}
                  selectedAnswer={completedQuestions[q.id]}/>);
            }
          })} 
        </div>
        <div className={styles.buttonContainer}>
          <Button 
            onClick={completeSection}
            ctaHighlight={false}>
            complete section
          </Button>
        </div>
      </div>
    );
  }
}

MathTest.propTypes = {
  section: PropTypes.object.isRequired,
  chooseAnswer: PropTypes.func.isRequired,
  completedQuestions: PropTypes.object.isRequired,
  completeSection: PropTypes.func.isRequired
};

MathTest.defaultProps = {
  completedQuestions: {}
};

export default MathTest;