import React from 'react';
import PropTypes from 'prop-types';

import styles from './ResultsBar.module.scss';

class ResultsBar extends React.Component {

  render() {
    const { rMax, rCurrent, lMax, lCurrent, lLabel, rLabel } = this.props;
    const lPercent = Math.round((lCurrent/lMax) * 100);
    const rPercent = Math.round((rCurrent/rMax) * 100);
    return (
      <div className={styles.fullBar}>
        <div className={styles.progressBar}>
          <span className={styles.labelL}>{lLabel}</span>
          <div className={styles.fillerL} style={{width: lPercent + '%'}}/>
        </div>
        <div className={styles.progressBar}>
          <span className={styles.labelR}>{rLabel}</span>
          <div className={styles.fillerR} style={{width: rPercent + '%'}}/>
        </div>
      </div>
    );
  }
}

ResultsBar.propTypes = {
  rMax: PropTypes.number,
  rCurrent: PropTypes.number,
  lMax: PropTypes.number,
  lCurrent: PropTypes.number,
  lLabel: PropTypes.string,
  rLabel: PropTypes.string,
};

ResultsBar.defaultProps = {
  rMax: 1,
  rCurrent: 1,
  lMax: 1,
  lCurrent: 1,
};

export default ResultsBar;