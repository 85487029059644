import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import styles from './TestSectionDirections.module.scss';


class TestSectionDirections extends React.Component {
    
  renderDirection(testTitle, totalTime) {
    const TestDirections = {
      'science':
        <div>
          <p><span>DIRECTIONS:</span> You will be given {totalTime} minutes to complete the 5 multiple-choice questions that accompany the following science passage. You may refer to the passage as often as necessary as you select your answers.</p>
          <p>You are NOT permitted to use a calculator on this test.</p>
        </div>,
      'math no calculator':
        <div>
          <p><span>DIRECTIONS:</span> You will be given {totalTime} minutes to complete the following 3 multiple-choice questions. For each question, choose the best answer from the choices provided. The use of a calculator is NOT permitted.
          </p>
        </div> , 
      'math': 
        <div>
          <p><span>DIRECTIONS:</span> You will be given {totalTime} minutes to complete the following 6 multiple-choice questions. For each question, choose the best answer from the choices provided. For question 5, enter your own response.
          </p>
          <p>The use of a calculator is permitted. Unless otherwise indicated, all variables and expressions used represent real numbers and figures are drawn to scale.</p>
         
        </div> , 
      'reading':
        <div>
          <p><span>DIRECTIONS:</span> You will be given {totalTime} minutes to complete the 10 multiple-choice questions that accompany the following reading passage. Choose the best answer to each question based on what is stated or implied in the passage. You may refer to the passage as often as necessary as you select your answers.</p>
        </div>
    };
    return TestDirections[testTitle.toLowerCase()];
  }

  render() {
    const { 
      testTitle, totalQuestions, onDismiss, totalTime } = this.props;

    return (
      <div className={styles.testSectionDirections}>
        <div className={styles.mainContainer}>        
          <div className={styles.header}>
            <span className={styles.subject}>{testTitle}</span>
            <span className={styles.details}>{totalTime} minutes - {totalQuestions} questions</span>
          </div>
                    
          {this.renderDirection(testTitle, totalTime)}
          <Button 
            ctaHighlight={true} 
            onClick={onDismiss}>
            GOT IT
          </Button>
        </div>
      </div>
    );
  }
}
    
TestSectionDirections.propTypes = {
  testTitle: PropTypes.string.isRequired,
  totalQuestions: PropTypes.number.isRequired,
  totalTime: PropTypes.number.isRequired,
  onDismiss: PropTypes.func.isRequired
};
    
export default (TestSectionDirections);