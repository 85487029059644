import React from 'react';
import PropTypes from 'prop-types';
import styles from './QuestionNavigator.module.scss';
import Button from './../Button';

class QuestionNavigator extends React.Component {
 
  backClicked = () => {
    const index = this.props.selectedIndex - 1;
    if (index >= 0)
      this.onQuestionSelected(index);
  }
 
  questionClicked = (index) => {
    this.onQuestionSelected(index);
  }

  nextClicked = () => {
    const index = this.props.selectedIndex + 1;
    if (index < this.props.questions.length)
      this.onQuestionSelected(index);
  }

  onQuestionSelected(index) {
    this.props.questionSelected(index)
  }

  renderNextButton() {
    const { selectedIndex, questions, completeSection } = this.props;
    const lastQuestion = selectedIndex >= questions.length-1;
    if (lastQuestion)
      return ( 
        <Button 
          onClick={completeSection}
          ctaHighlight={false}>
          complete section
        </Button>
      );
    else 
    return ( 
      <Button 
        onClick={this.nextClicked}>
        next
      </Button>
    );

  }

  render() {
    const { selectedIndex } = this.props;
    
    return (
      <div className={styles.questionNavigator}>
        <Button 
          onClick={this.backClicked}
          disabled={selectedIndex <= 0}>
          back
        </Button>
        {this.renderNextButton()}
      </div>
    );
  }ß
}

QuestionNavigator.propTypes = {
  selectedIndex: PropTypes.number.isRequired,
  questions: PropTypes.array.isRequired,
  questionSelected: PropTypes.func.isRequired,
  completeSection: PropTypes.func.isRequired
};

QuestionNavigator.defaultProps = {
  selectedIndex: 0
};

export default QuestionNavigator;