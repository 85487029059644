import readingPassage1 from './readingPassage1.jsx';
import sciencePassage from './sciencePassage.jsx';
import mathConstants from './mathConstants.jsx';

export default [
  {
    time: 600,
    extendedTime: 1200,
    type: 'test',
    sectionIndex: 1,
    subject: 'Reading',
    passage: readingPassage1,
    questions: [
      {
        id: 53100,
        body: 'Over the course of the passage, the author\'s view of the main subject shifts from',
        answers: [
          'disinterest to curiosity.',
          'oversimplification to understanding.',
          'skepticism to respect.',
          'astonishment to belief.',
        ],
        correctAnswer: 1,
      },
      {
        id: 53101,
        body: 'As it is used in line 19, "lode" most nearly means',
        answers: [
          'abundance',
          'environment',
          'scarcity',
          'history',
        ],
        correctAnswer: 0,
      },
      {
        id: 53102,
        body: 'As it is used in line 21, "jettisoned" most nearly means',
        answers: [
          'soaked',
          'small',
          'sacrificed',
          'stolen',
        ],
        correctAnswer: 2,
      },
      {
        id: 53103,
        body: 'The author indicates that mangrove cays primarily benefit coral reefs by',
        answers: [
          'defending them from waves.',
          'providing fertilizer that accelerates their growth.',
          'preventing too much algae from building up.',
          'filtering soil and harmful chemicals.',
        ],
        correctAnswer: 3,
      },
      {
        id: 53104,
        body: 'Which choice provides the best evidence for the answer to the previous question?',
        answers: [
          'Lines 36-37 ("Mesoamerican...system")',
          'Lines 38-41 ("The second...marine ridges.")',
          'Lines 44-47 "(The clusters...from land.")',
          'Lines 48-52 ("The mangroves...and crocodiles.")',
        ],
        correctAnswer: 2,
      },
      {
        id: 53105,
        body: 'It can reasonably be inferred from the passage that',
        answers: [
          'most of the young fish in the mangroves will not remain there as adults.',
          'the mangroves face many different threats to their survival.',
          'parrotfish are the most important species of marine life on the reef.',
          'catch-and-release fishing is permitted along the Mesoamerican reef.',
        ],
        correctAnswer: 0,
      },
      {
        id: 53106,
        body: 'Which choice provides the best evidence for the answer to the previous question?',
        answers: [
          'Lines 18-21 ("I contemplated...Heyman said.")',
          'Lines 31-35 ("The palest...open ocean?")',
          'Lines 36-41 ("Mesoamerican mangroves...marine ridges.")',
          'Lines 71-73 ("When the...normally eat.")',
        ],
        correctAnswer: 1,
      },
      {
        id: 53107,
        body: 'According to the passage, the parrotfish\'s diet consists of',
        answers: [
          'moquitoes.',
          'tiny worms.',
          'algae.',
          'sea grass.',
        ],
        correctAnswer: 2,
      },
      {
        id: 53108,
        body: 'The passage makes clear that compared to the life forms above the water, the life forms below are',
        answers: [
          'foreign and strange.',
          'plentiful and wild.',
          'interconnected and vibrant.',
          'colorful and varied.',
        ],
        correctAnswer: 3,
      },{
        id: 53109,
        body: 'The purpose of the last paragraph (lines 65-77) is to',
        answers: [
          'provide a counterexample to the Heyman\'s claims about marine biology.',
          'demonstrate the interdependent nature of the reef\'s ecosystem.',
          'illustrate the parrotfish\'s life cycle in greater detail.',
          'emphasize the importance of the mangroves to the parrotfish.',
        ],
        correctAnswer: 1,
      }
    ]
  },
  {
    time: 30,
    extendedTime: 59,
    type: 'break'
  },
  {
    time: 420,
    extendedTime: 840,
    type: 'test',
    sectionIndex: 2,
    subject: 'Math',
    questions: [
      {
        id: 53113,
        body: 'The equation for Newton\'s law of universal gravitation is <br><br> [math]F = G\\frac{m_{1}m_{2}}{r^2}[/math] <br><br> where [math]F[/math] is the gravitational force acting between two objects, [math]m_{1}[/math] and [math]m_{2}[/math] are the masses of the objects, [math]r[/math] is the distance between the centers of their masses, and [math]G[/math] is the gravitational constant. <br><br> What is [math]r[/math] in terms of [math]F[/math]?',
        answers: [
          '[math]r = \\sqrt{\\frac{Gm_{1}m_{2}}{F}}[/math]',
          '[math]r = \\frac{Gm_{1}m_{2}}{F}[/math]',
          '[math]r = \\frac{F}{Gm_{1}m_{2}}[/math]',
          '[math]r = \\frac{\\sqrt{Gm_{1}m_{2}}}{F}[/math]'
        ],
        correctAnswer: 1
      },
      {
        id: 53114,
        body: '<img src="https://d14qx55ky13qwa.cloudfront.net/questions/mccorkle/00105/Frame0.png"><br/> In triangle [math]ABC[/math], [math]AX = 3[/math], [math]XC = 5[/math], and [math]AB = 8[/math]. What is the area of triangle [math]XBC[/math]?',
        answers: [
          '8',
          '15',
          '18',
          '20',
          '24'
        ],
        correctAnswer: 4
      },
      {
        id: 53115,
        body: 'If [math]\\frac{7^a}{7^b}=49[/math], and [math]a^2 - b^2 = 8[/math], what is the value of [math]a + b[/math]?',
        answers: [
          '[math]2[/math]',
          '[math]4[/math]',
          '[math]6[/math]',
          'The value cannot be determined from the given information.'
        ],
        correctAnswer: 2
      },
      {
        id: 53116,
        body: mathConstants.q53116b,
        answers: [
          '[math]18\\pi[/math] [math]in^2[/math]',
          '[math]16\\pi[/math] [math]in^2[/math]',
          '[math]12\\pi[/math] [math]in^2[/math]',
          '[math]7\\pi[/math] [math]in^2[/math]',
          '[math]5\\pi[/math] [math]in^2[/math]'
        ],
        correctAnswer: 3
      },
      {
        id: 53117,
        body: 'The amount of snowfall, [math]S[/math], in inches, at a time [math]t[/math] hours after the start of a blizzard is given by the equation [math]S = 1.5t[/math]. How much snow will fall from the end of the fourth hour to the end of the seventh hour of the blizzard?',
        answers: [
        ],
        correctAnswer: 4.5,
        gridin: true,
      },
      {
        id: 53118,
        body: 'If [math]log_{x}(10)=x[/math], which of the following is the closest approximation of [math]x[/math]?',
        answers: [
          '0',
          '1',
          '1.5',
          '2',
          '2.5'
        ],
        correctAnswer: 5
      }
    ]
  },
  {
    time: 30,
    extendedTime: 59,
    type: 'break'
  },
  {
    time: 300,
    extendedTime: 600,
    type: 'test',
    sectionIndex: 3,
    subject: 'Math No Calculator',
    questions: [
      { 
        id: 53110,
        body: '[math]y = x^2 + x - 6[/math]<br>At which point(s) does the above equation intersect with the x-axis?',
        answers: [
          '[math](-2,0)[/math] and [math](3,0)[/math]',
          '[math](2,0)[/math] and [math](-3,0)[/math]',
          '[math](0,-6)[/math]',
          '[math](-1,0)[/math] and [math](6,0)[/math]'
        ],
        correctAnswer: 2
      },
      {
        id: 53111,
        body: mathConstants.q53111b,
        answers: [
          '[math]4,600[/math]',
          '[math]1,050[/math]',
          '[math]980[/math]',
          '[math]920[/math]',
        ],
        correctAnswer: 4
      },
      {
        id: 53112,
        body: mathConstants.q53112b,
        answers: [
          '2010-2015',
          '2005-2010',
          '2000-2005',
          '1995-2000',
        ],
        correctAnswer: 3
      }
    ],
  },
  {
    time: 30,
    extendedTime: 59,
    type: 'break'
  },
  {
    time: 400,
    type: 'test',
    sectionIndex: 4,
    subject: 'Science',
    passage: sciencePassage,
    questions: [
      {
        id: 53130,
        body: 'If two pieces of metal both eject an electron when hit by photons of equal energy, which of the following must be true?',
        answers: [
          'The metals are the same material.',
          'The electrons have have the same kinetic energy.',
          'The photon frequency is greater than both metal threshold frequencies.',
          'The difference between the K(max) of the two electrons is no greater than [math]4.0 x 10^{-19}[/math] joules.',
        ],
        correctAnswer: 2,
      },
      {
        id: 53131,
        body: 'Given equivalent exposure to light, which metal is most likely to have a positive charge?',
        answers: [
          'Potassium',
          'Lead',
          'Mercury',
          'Beryllium',
        ],
        correctAnswer: 0,
      },
      {
        id: 53132,
        body: 'If an unidentified metal ejects an electron with [math]6.0 x 10^{-19}[/math] joules of energy when it comes into contact with a photon that has a frequency of [math]14.0 x 10^{14}[/math] Hz, which material would that metal most likely be?',
        answers: [
          'Potassium',
          'Lead',
          'Mercury',
          'Beryllium',
        ],
        correctAnswer: 0,
      },
      {
        id: 53133,
        body: 'What is the greatest amount of energy a photon can possess without dislodging an electron from a Beryllium substance?',
        answers: [
          '[math]4.0 x 10^{-19}[/math] joules',
          '[math]6.0 x 10^{-19}[/math] joules',
          '[math]8.0 x 10^{-19}[/math] joules',
          '[math]10.0 x 10^{-19}[/math] joules',
        ],
        correctAnswer: 2,
      },
      {
        id: 53134,
        body: 'A photon’s frequency can be determined by its wavelength using the following equation where c is the speed of light in a vacuum and &lambda; is the photon’s wavelength.</br></br>[math]\\frac{c}{\\lambda}=f[/math]</br></br>If Photon A is able to dislodge an electron from a piece of unidentified metal and Photon B is not, which photon would have the greater wavelength?',
        answers: [
          'Photon A',
          'Photon B',
          'The wavelengths would be the same.',
          'There is not enough information to answer.',
        ],
        correctAnswer: 1,
      },
    ],
  },
];