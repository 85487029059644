import React from 'react';
import PropTypes from 'prop-types';

import KatexText from '../KatexText';

import styles from './GridinQuestion.module.scss';

class GridinQuestion extends React.Component {
 
  answerInput = (event) => {
    console.log('i am changing');
    let value = event.target.value;
    if (/^[0-9./]{0,4}$/.test(value)) {
      if(value.length > 0) {
        this.props.chooseAnswer(this.props.questionID, value);
      }
      else {
        this.props.chooseAnswer(this.props.questionID, 'None');
      }
    }
    else {
      value = value.substring(0, 4);
      event.target.value = value.replace(/[^0-9./]+/g, '');
    }
  }
  
  render() {
    const { questionNumber, questionBody, selectedAnswer } = this.props;

    let val = '';
    if (selectedAnswer && selectedAnswer !== 'None') {
      val = selectedAnswer;
    }

    return (
      <div className={styles.question}>
        <div className={styles.questionBody}>
          <div className={styles.questionNumber}>{questionNumber}.</div>
          <KatexText text={questionBody} />
        </div>
        <div className={styles.answerInput}>
          <input type='number' onChange={this.answerInput.bind(this)} defaultValue={val} />
        </div>
      </div>
    );
  }
}

GridinQuestion.propTypes = {
  questionID: PropTypes.number.isRequired,
  questionNumber: PropTypes.number.isRequired,
  questionBody: PropTypes.string.isRequired,
  answerOptions: PropTypes.array.isRequired,
  chooseAnswer: PropTypes.func.isRequired,
  selectedAnswer: PropTypes.number,
};

export default GridinQuestion;