import React from 'react';
import PropTypes from 'prop-types';
import Images from '../../assets/Images';

import Timer from '../Timer';

import styles from './TestHeader.module.scss';

class TestHeader extends React.Component {
  render() {
    const { 
      section, 
      completeSection, 
      updateTime, 
      timeSaved, 
      extendedTime,
      showTimer } = this.props;
  
    const sectionTime = extendedTime ? section.extendedTime : section.time;

    return (
      <div className={styles.header}>
        <div className={styles.logo}>
          <img src={Images.logo} alt='sly logo'></img>
        </div>
        <div className={styles.headerBody}>
          <div className={styles.title}>
            <div className={styles.title1}>{section.subject}</div>
            <div className={styles.title2}>{section.questions.length} questions - {Math.floor(sectionTime/60)} minutes
            </div>
          </div>
          <div className={styles.timer}>
            { showTimer ?
              <Timer
                key={section.subject}
                timeSaved={timeSaved}
                timeInSeconds={sectionTime}
                onTimerComplete={completeSection}
                notifyParent={updateTime}
              />: null}
          </div>
        </div>
            
      </div>
    );
  }
}

TestHeader.propTypes = {
  section: PropTypes.object,
  pauseSession: PropTypes.func,
  completeSection: PropTypes.func,
  updateTime: PropTypes.func,
  timeSaved: PropTypes.number,
  showTimer: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool,
  extendedTime: PropTypes.bool,
};

TestHeader.defaultProps = {
  showTimer: true,
};

export default TestHeader;