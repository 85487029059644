import React from 'react';
import PropTypes from 'prop-types';
import Question from './../Question';
import QuestionNavigator from './../QuestionNavigator';
import styles from './QuestionSet.module.scss';
import Button from './../Button';

class QuestionSet extends React.Component {

  questionSelected = (questionID) => {
    this.props.currentQuestionChange(questionID);
  }

  render() {
    const { 
      chooseAnswer,
      currentQuestion, 
      questions, 
      completedQuestions,
      isDesktop,
      completeSection } = this.props;
    
    if (isDesktop) {
      const allQuestions = questions.map((q,i) => 
      {
        return (
          <Question 
            key={q.id}
            questionNumber={i+1}
            questionID={q.id}
            questionBody={q.body}
            answerOptions={q.answers}
            chooseAnswer={chooseAnswer}
            selectedAnswer={completedQuestions[q.id]}/>
        );
      }); 
      return (
      <div className={styles.questionSet}>
        <div className={styles.questionList}>
          {allQuestions}
        </div>
        <Button 
          onClick={completeSection}>
          complete section
        </Button>
      </div>);
    }
    else {
      const q = questions[currentQuestion];
      return (
        <div className={styles.questionSet}>
          <div className={styles.questionContainer}>
            <Question 
                key={q.id}
                questionNumber={currentQuestion+1}
                questionID={q.id}
                questionBody={q.body}
                answerOptions={q.answers}
                chooseAnswer={chooseAnswer}
                selectedAnswer={completedQuestions[q.id]}/>
          </div>
          <QuestionNavigator
            selectedIndex={currentQuestion}
            questions={questions}
            questionSelected={this.questionSelected}
            completeSection={completeSection}
          />
        </div>
      );
    }
  }
}

QuestionSet.propTypes = {
  questions: PropTypes.array.isRequired,
  chooseAnswer: PropTypes.func.isRequired,
  currentQuestionChange: PropTypes.func,
  currentQuestion: PropTypes.number.isRequired,
  completedQuestions: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool,
  completeSection: PropTypes.func.isRequired
};


export default QuestionSet;