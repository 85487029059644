import React, {Component} from 'react';

export default function DetectDesktopOrMobile(ComposedClass) {
  return class DetectDesktopOrMobile extends Component {
    _isMounted = false;
    constructor() {
      super();
      this.state = {
        width: window.innerWidth,
      };
    }
    componentDidMount() {
      this._isMounted = true;
      window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
      this._isMounted = false;
      window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
      if (this._isMounted) {
        this.setState({ width: window.innerWidth });
      }
    };
   
    is_touch_device() {
      var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      var mq = function(query) {
        return window.matchMedia(query).matches;
      };
    
      if (('ontouchstart' in window) || window.DocumentTouch) {
        return true;
      }
    
      // include the 'heartz' as a way to have a non matching MQ to help terminate the join
      // https://git.io/vznFH
      var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    }

    is_desktop() {
      if(this.is_touch_device() || this.state.width <= 600) {
        return false;
      }
      return true;
    } 

    render() {
      return <ComposedClass 
        isDesktop={this.is_desktop()} 
        {...this.props} />;
    }
  };
}
