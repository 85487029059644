import { EventTypes } from 'redux-segment';
import * as constants from '../constants/actions';


export function updateSessionState(time, questionID) {
  return {
    type: constants.UPDATE_SESSION_STATE,
    time: time,
    currentQuestion: questionID
  };
}

export function completeTestSection(sectionTime) {
  return {
    type: constants.COMPLETE_TEST_SECTION,
    sectionTime: sectionTime,
  };
}

export function setExtendedTime(value) {
  return {
    type: constants.SET_EXTENDED_TIME,
    value: value,
  };
}

export function toggleInstructionModal() {
  return {
    type: constants.TOGGLE_INSTRUCTION_MODAL
  };
}

export function startSession(userEmail) {
  let analytics = {
    eventType: EventTypes.track,
    eventPayload: {
      userId: userEmail,
      event: 'Test Selector: Started',
      properties: {
        testStarted: Date.now()
      },
    }
  };
  return {
    type: constants.START_SESSION,
    meta: {
      analytics
    }
  };
}

export function pauseSession(timeSaved) {
  return {
    type: constants.PAUSE_SESSION,
    timeSaved: timeSaved
  };
}

export function resumeSession() {
  return {
    type: constants.RESUME_SESSION
  };
}

export function chooseAnswer(testSectionId, question, answer, timeSaved) {
  return {
    type: constants.CHOOSE_ANSWER,
    testSectionId: testSectionId,
    question: question,
    answer: answer,
    timeSaved: timeSaved
  };
}

export function gradeTest(answers) {
  return {
    type: constants.GRADE_TEST,
    answers: answers,
  };
}

function saveTestRequest() {
  return {
    type: constants.SAVE_TEST_REQUEST,
    meta: {
    }
  };
}

function saveTestSuccess() {

  return {
    type: constants.SAVE_TEST_SUCCESS,
    receivedAt: Date.now(),
  };
}

function saveTestFailure(error) {
  return {
    type: constants.SAVE_TEST_FAILURE,
    error: error,
    failureAt: Date.now()
  };
}

export function saveTest(user_uuid, answers, favor) {
  return function (dispatch) {
    dispatch(saveTestRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let formData = [];
    formData.push(encodeURIComponent('user_uuid') + '=' + encodeURIComponent(user_uuid));
    formData.push(encodeURIComponent('answers') + '=' + encodeURIComponent(JSON.stringify(answers)));
    formData.push(encodeURIComponent('favor') + '=' + encodeURIComponent(favor));
  
    return fetch('https://app.testive.com/api/reporting/selector_results/', {
      method: 'POST',
      body: formData.join('&'),
      headers: header
    }).then(
      response => response.json(),
      error => dispatch(saveTestFailure(error.message))
    )
      .then(
        function(json) {
          if (json.status === 'ok') {
            dispatch(saveTestSuccess());
          } else if(json.status === 'error'){
            dispatch(saveTestFailure(json.msg));
          }
        }
      );
  }; 
}

function loadTestRequest() {
  return {
    type: constants.LOAD_TEST_REQUEST
  };
}

function loadTestSuccess(data) {

  return {
    type: constants.LOAD_TEST_SUCCESS,
    receivedAt: Date.now(),
    answers: data,
  };
}

function loadTestFailure(error) {
  return {
    type: constants.LOAD_TEST_FAILURE,
    error: error,
    failureAt: Date.now()
  };
}

export function loadTest(user_uuid) {
  return function (dispatch) {
    dispatch(loadTestRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return fetch('https://app.testive.com/api/reporting/selector_results/?user_uuid=' + user_uuid, {
      headers: header
    }).then(
      response => response.json(),
      error => dispatch(loadTestFailure(error.message))
    )
      .then(
        function(json) {
          if (json.status === 'ok') {
            dispatch(loadTestSuccess(json.data));
          } else if(json.status === 'error'){
            dispatch(loadTestFailure(json.msg));
          }
        }
      );
  };
}