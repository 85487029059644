import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ResultsBar from '../ResultsBar';
import { verifyUser, loadTest } from '../../actions';

import Images from '../../assets/Images';
import styles from './TestResults.module.scss';

class TestResults extends React.Component {
  
  componentDidMount() {
    this.props.verifyUser({user_uuid: this.props.match.params.id});
    this.props.loadTest(this.props.match.params.id);
  }

  renderTestInfo() {
    const { displayName } = this.props;
    return (
      <div className={styles.infoHeader}>
        {displayName}
      </div>
    );
  }

  renderHeader() {
    const { stronglyFavored, favoredTest, otherTest } = this.props;
 
    if (stronglyFavored)
      return (
        <div className={styles.subHeader}>
          Based on your performance, the <span>{favoredTest}</span> is the right test for you. 
          Here’s why:
        </div>
      );
    else
      return (
        <div className={styles.subHeader}>
          Based on your performance on this assessment, we believe that the <span>{favoredTest}</span> is 
          the right test for you, but you might still benefit from holding onto the {otherTest} as 
          an option. We’d suggest that you start with the <span>{favoredTest}</span> and 
          consider the {otherTest} as a backup plan. You'll find an analysis below that shows why we suggest you start with the <span>{favoredTest}</span>. 
To help you interpret the results and take the next step in preparing for the <span>{favoredTest}</span>, we recommend you 
<a href="https://www.testive.com/schedule-a-call/">schedule a free consultation with a Testive prep expert now.</a>
        </div>
      );
  }

  renderData() { 
    const breakdown = this.props.score.breakdown;
    const { favoredTest } = this.props;

    if (favoredTest === 'ACT') {
      return (
        <div className={styles.breakdown}>
          <div className={styles.data}>
            <div className={styles.dataEntry}>
              <div className={styles.dataHeader}>Reading</div>
              <ResultsBar lMax={5} lCurrent={breakdown['SAT']['Reading']} rMax={5} rCurrent={breakdown['ACT']['Reading']} lLabel={'SAT'} rLabel={'ACT'}/>
              {
                breakdown['ACT']['Reading'] > 0 ?
                  <div className={styles.dataText}>You did well on questions using the style and format of the ACT reading section. There are a few subtle and not-so-subtle differences between the tone, structure, and content of the ACT and SAT reading tests. These differences include the way that questions refer to lines and phrases from the text, the wording that questions use to convey what they are asking you to do, and importantly, the type of information that questions ask you to retain or retrieve from the passage. Your performance in the reading section indicates that you are more inclined towards the question-passage relationships found in the ACT than those found in the SAT</div>
                  :
                  <div className={styles.dataText}>Both the SAT and the ACT require you to read and comprehend complex texts within limited time frames. You have to balance the time required to process the text with the time required to process and accurately answer the questions that follow. This is not an easy task but it can be improved with practice. Your overall performance on the assessment suggests that you&#39;re better suited to the ACT, but we still recommend that you make practice on ACT-style passage and question sets a priority.</div>
              }
            </div>
            <div className={styles.dataEntry}>
              <div className={styles.dataHeader}>Math</div>
              <ResultsBar lMax={6} lCurrent={breakdown['SAT']['Math no Calculator'] + breakdown['SAT']['Math with Calculator']} rMax={3} rCurrent={breakdown['ACT']['Math no Calculator']}  lLabel={'SAT'} rLabel={'ACT'}/>
              {
                breakdown['ACT']['Speed']['Math no Calculator'] ?
                  <div className={styles.dataText}>The Math No Calculator section is designed to test your ability performing math without a calculator accurately within the time limit. As you&#39;re probably aware, there is only a “no calculator” section on the SAT, not the ACT. But, math is math (for the most part -- there are crucial differences between the tests, and we have taken those into account), and you worked through the questions in this section at a really great pace. Overall, the ACT is a faster test than the SAT (you get less time per question in every section), so in the context of your overall performance on this assessment, it’s certainly meaningful that you were able to perform well on this section in under the time limit.</div>
                  :
                  breakdown['ACT']['Math no Calculator'] >= 2 ?
                    <div className={styles.dataText}>The Math No Calculator section is designed to test your ability performing math without a calculator accurately within the time limit. As you&#39;re probably aware, there is only a “no calculator” section on the SAT, not the ACT. However, doing well on this section doesn’t mean you shouldn’t take the ACT -- math is math (for the most part -- there are crucial differences between the tests, and we have taken those into account), and your ability to accurately solve problems without a calculator will come in handy on either test. In the context of your overall performance on this assessment, the ACT is the right test for you.</div>
                    :
                    <div className={styles.dataText}>The Math No Calculator section is designed to test your ability performing math without a calculator accurately within the time limit. As you&#39;re probably aware, there is only a “no calculator” section on the SAT, not the ACT. Your overall performance on the assessment suggests you should take the ACT. However, that doesn’t mean you shouldn’t practice solving math problems without the aid of a calculator. Especially because the ACT is a fast-moving test, it is important to have flexibility and fluidity with mental calculations.</div>
              }
              {
                breakdown['ACT']['Speed']['Math with Calculator'] ?
                  <div className={styles.dataText}>As for the Math with Calculator problems, the major distinction between the tests here has to do with the type and quantity of problems featured on each test, as well as the pacing of the sections. Your performance in this section shows that your abilities better lend themselves to the ACT, particularly in terms of pacing. That doesn’t mean you should only study one type of math at the expense of ignoring another, but starting with the ACT should point you in the right direction.</div>
                  :
                  <div className={styles.dataText}>As for the Math with Calculator problems, the major distinction between the tests here has to do with the type and quantity of problems featured on each test, as well as the pacing of the sections. Your performance in this section shows that your abilities better lend themselves to the ACT. That doesn’t mean you should only study one type of math at the expense of ignoring another, but starting with the ACT should point you in the right direction. It will also be important for you to practice working through math problems at a good clip, because the questions move fast on the ACT, and you might need a bit of work there.</div>
              }
            </div>
            <div className={styles.dataEntry}>
              <div className={styles.dataHeader}>Science</div>
              <ResultsBar lMax={5} lCurrent={5 - breakdown['ACT']['Science']} rMax={5} rCurrent={breakdown['ACT']['Science']}  lLabel={'SAT'} rLabel={'ACT'}/>
              {
                breakdown['ACT']['Science'] >= 3 ?
                  <div className={styles.dataText}>The science section was pretty tough, wasn’t it? You did really well on it! The ACT requires you to work through a lot of information very quickly in order to succeed on this section, and you demonstrated that you’re highly capable of doing so. Success on science questions is a good predictor of your ability to perform well on the ACT overall.</div>
                  :
                  breakdown['ACT']['Science'] === 2 ?
                    <div className={styles.dataText}>The science section was pretty tough, wasn’t it? You demonstrated success in thinking logically and gathering information in order to solve questions. However, the ACT requires you to be able to do this really, really quickly. You can definitely improve at this. Your overall performance on this assessment suggests that you should take the ACT, but we would still recommend that pacing activities form a significant part of your prep.</div>
                    :
                    <div className={styles.dataText}>The science section was pretty tough, wasn’t it? The thing is, it’s tough for everybody, and it’s definitely possible to improve. Your overall performance on this assessment suggests that you should take the ACT, but we would recommend that science section practice form a significant part of your prep.</div>
              }
            </div>
            <div className={styles.dataEntry}>
              <div className={styles.dataHeader}>English</div>
              <ResultsBar lMax={10} lCurrent={1} lLabel={'SAT'} rLabel={'ACT'}/>
              <div className={styles.dataText}>
                Wait, did I miss something? I don’t remember doing an English test…
                <br/><br/>
                That’s because we didn’t have you do one. Both the SAT and the ACT feature a section focused on improving written passages by correcting errors in grammar and meaning. Although there are some slight differences between the two tests in these sections, we can give you a recommendation by looking at your performance in areas that differ more significantly. We know you’re busy and we don’t want to waste your time.
              </div>
            </div>
            <div className={styles.dataEntry}>
              <div className={styles.dataHeader}>Summary</div>
              <ResultsBar lMax={12} lCurrent={this.props.score['SAT']} rMax={12} rCurrent={this.props.score['ACT']}  lLabel={'SAT'} rLabel={'ACT'}/>
              {
                breakdown['questionsCorrect'] >= 19 ?
                  <div className={styles.dataText}>Nice job! You correctly answered the majority of the questions on this assessment. As the above analysis indicates, your particular skill set is likely best suited for the ACT, but with the right preparation, you could certainly have success with either test. You are ready to get off to a strong start, and we wouldn’t be surprised if you would benefit from preparing for certain topics and question types on the ACT more than others. Your next step should probably be to take a full practice test so you can figure out exactly how to most efficiently direct your preparatory efforts. This is best achieved with expert guidance. Give yourself a big pat on the back!</div>
                  :
                  breakdown['questionsCorrect'] >= 12 ?
                    <div className={styles.dataText}>Nice work! This assessment is designed to recommend whether you should focus your efforts on the SAT or the ACT, not to predict your score, but you should still give yourself a pat on the back for getting the majority of the questions correct! As the above analysis indicates, your performance suggests that you are likely best suited for the ACT. You’d definitely benefit from focused preparation efforts before you take the test. In order to best determine where to direct your preparation, we recommend taking a full diagnostic practice test as a next step. This is the first step in any efficient, effective test prep plan. So what are you waiting for? Get to it!</div>
                    :
                    <div className={styles.dataText}>Give yourself a pat on the back! You’ve taken the first step, which is the hard part...but now comes the harder part. According to your performance on this assessment, we recommend that you focus on the ACT. However, you should prepare for the test beforehand in order to give yourself the best chance of success. Although this assessment isn’t designed to predict your score, it’s safe to say that you’ll benefit from practice in most areas of the test. In order to most effectively figure out where to direct your efforts, we recommend taking a diagnostic practice test as a next step. You’ll probably get the most out of it if you do so with expert guidance.</div>
              }
            </div>
          </div>
        </div>
      );
    }
    if (favoredTest === 'SAT') {
      return (
        <div className={styles.breakdown}>
          <div className={styles.data}>
            
            <div className={styles.dataEntry}>
              <div className={styles.dataHeader}>Reading</div>
              <ResultsBar lMax={5} lCurrent={breakdown['ACT']['Reading']} rMax={5} rCurrent={breakdown['SAT']['Reading']}  lLabel={'ACT'} rLabel={'SAT'}/>
              {
                breakdown['SAT']['Word Meaning'] > 0 ?
                  <div className={styles.dataText}>You did well on the Words in Context questions. These are the questions that asked you to determine the meaning of a word or phrase in the context of the passage. Of course, most of the SAT isn&#39;t about figuring out the meanings of words. However, these questions do make up a significant portion of the SAT reading section, especially compared to the ACT.</div>
                  : null
              }
              {
                breakdown['SAT']['Citing Evidence'] > 0 ?
                  <div className={styles.dataText}>You did well on the citing evidence questions. These are the questions that asked you to support the answer to the previous question with evidence from the passage. Many students find these questions to be the most difficult part the SAT reading test because they are complex, requiring you to keep track of a lot of information in order to choose the best answer. These questions always make up a significant portion of the SAT reading test, so if you can succeed at them, you have a head start, and a leg up compared to what your performance would be on the ACT.</div>
                  : null
              }
              {
                breakdown['SAT']['Line Reference'] > 0 ?
                  <div className={styles.dataText}>You succeeded when answering questions that give a direct reference to the text. One difference between the SAT and the ACT is that the SAT provides more line number references. The tradeoff here is that sometimes the questions themselves are more complex. If you do well when you are pointed directly to the text, the SAT is better-suited for you.</div>
                  : null
              }
              {
                breakdown['SAT']['Reading'] === 0 ?
                  <div className={styles.dataText}>Both the SAT and the ACT require you to read and comprehend complex texts within limited time frames. You have to balance the time required to process the text with the time required to process and accurately answer the questions that follow. This is not an easy task but it can be improved with practice. Your overall performance on the assessment suggests that you&#39;re better suited to the SAT, but we still recommend that you make practice on SAT-style passage and question sets a priority.</div>
                  : null
              }
            </div>
            <div className={styles.dataEntry}>
              <div className={styles.dataHeader}>Math</div>
              <ResultsBar lMax={3} lCurrent={breakdown['ACT']['Math with Calculator']} rMax={6} rCurrent={breakdown['SAT']['Math no Calculator'] + breakdown['SAT']['Math with Calculator']} lLabel={'ACT'} rLabel={'SAT'}/>
              {
                breakdown['SAT']['Math no Calculator'] >= 2 ?
                  <div className={styles.dataText}>You did well on the math questions that don’t allow use of a calculator. This is one of the most significant differences between the SAT and the ACT, and it&#39;s the most significant difference in the math sections. The math with no calculator test forms more than one-third of your SAT math score, so if you have a strong ability to effectively and efficiently perform math mentally and/or without the use of a calculator, you have an advantage when it comes to taking the SAT. That doesn&#39;t mean that the same skills don&#39;t work in your favor on the ACT, but our job here is to choose which test is best for you, which means we&#39;re looking at where you have an edge.</div>
                  :
                  <div className={styles.dataText}>One of the most significant differences between the SAT and the ACT is the math section on the SAT that doesn’t allow the use of a calculator. Although your overall performance on this assessment suggests that you should take the SAT, this is an area where you could use some improvement. We recommend that practice with solving math problems mentally and on paper should form a significant part of your prep. Flexibility and fluidity with calculations is key to success on the SAT (or ACT) math test.</div>
              }
              {
                breakdown['SAT']['Math with Calculator'] >= 1 ?
                  <div className={styles.dataText}>You did well on math questions that suggest the SAT is the right test for you. As college readiness tests, both the SAT and the ACT emphasize problem-solving and data analysis, but when considering the context of your performance on this test regarding pacing and question type, the SAT is the better choice. For example, you’re likely to be more successful on a math section that is tilted toward algebra, and the ACT is proportionally more plane-and-coordinate-geometry-heavy than the SAT.</div>
                  :
                  <div className={styles.dataText}>As for the Math with Calculator problems, the major distinction between the tests here has to do with the type and quantity of problems featured on each test, as well as the pacing of the sections. Your math performance shows that your abilities better lend themselves to the SAT, but that doesn’t mean you wouldn’t benefit from practice for that exam. We recommend starting with review of algebra fundamentals as you prep for the math test.</div>
              }
            </div>
            <div className={styles.dataEntry}>
              <div className={styles.dataHeader}>Science</div>
              <ResultsBar lMax={5} lCurrent={5 - breakdown['ACT']['Science']} rMax={5} rCurrent={breakdown['ACT']['Science']}  lLabel={'ACT'} rLabel={'SAT'} />
              {
                breakdown['ACT']['Science'] >= 3 ?
                  <div className={styles.dataText}>The science section was pretty tough, wasn’t it? You actually did really well on it, but given your performance on the assessment overall, we are still going to recommend that you take the SAT. The good news is that because you did well here, you have demonstrated more than just an ability to work through a science section -- the questions in this section require similar skills to the problem-solving and data analysis questions and graph interpretation questions that form prominent parts of other sections of the SAT.</div>
                  :
                  <div className={styles.dataText}>The science section was pretty tough, wasn’t it? It’s tough for everybody. The good news is that you demonstrated success in thinking logically and gathering information in order to solve questions. The bad news is that the ACT requires you to be able to do this really, really quickly. The good news (there’s more good news?! what?!) is that the whole point of this selector is to choose which test is best for you, and in your case, right now, that’s the SAT.</div>
              }
            </div>
            <div className={styles.dataEntry}>
              <div className={styles.dataHeader}>Writing and Language</div>
              <ResultsBar lMax={1} lCurrent={0}  lLabel={'ACT'} rLabel={'SAT'}/>
              Wait, did I miss something? I don’t remember doing a Writing and Language test…
              <br/><br/>
              That’s because we didn’t have you do one. Both the SAT and the ACT feature a section focused on improving written passages by correcting errors in grammar and meaning. Although there are some slight differences between the two tests in these sections, we can give you a recommendation by looking at your performance in areas that differ more significantly. We know you’re busy and we don’t want to waste your time.
            </div>
            <div className={styles.dataEntry}>
              <div className={styles.dataHeader}>Summary</div>
              <ResultsBar lMax={12} lCurrent={this.props.score['ACT']} rMax={12} rCurrent={this.props.score['SAT']}  lLabel={'ACT'} rLabel={'SAT'} />
              {
                breakdown['questionsCorrect'] >= 19 ?
                  <div className={styles.dataText}>Nice job! You correctly answered the majority of the questions on this assessment. As the above analysis indicates, your particular skill set is likely best suited for the SAT, but with the right preparation, you could certainly have success with either test. You are ready to get off to a strong start, and we wouldn’t be surprised if you would benefit from preparing for certain topics and question types on the SAT more than others. Your next step should probably be to take a full practice test so you can figure out exactly how to most efficiently direct your preparatory efforts. This is best achieved with expert guidance. Give yourself a big pat on the back!</div>
                  :
                  breakdown['questionsCorrect'] >= 12 ?
                    <div className={styles.dataText}>Nice work! This assessment is designed to recommend whether you should focus your efforts on the SAT or the ACT, not to predict your score, but you should still give yourself a pat on the back for getting the majority of the questions correct! As the above analysis indicates, your performance suggests that you are likely best suited for the SAT. You’d definitely benefit from focused preparation efforts before you take the test. In order to best determine where to direct your preparation, we recommend taking a full diagnostic practice test as a next step. This is the first step in any efficient, effective test prep plan. So what are you waiting for? Get to it!</div>
                    :
                    <div className={styles.dataText}>Give yourself a pat on the back! You’ve taken the first step, which is the hard part...but now comes the harder part. According to your performance on this assessment, we recommend that you focus on the SAT. However, you should prepare for the test beforehand in order to give yourself the best chance of success. Although this assessment isn’t designed to predict your score, it’s safe to say that you’ll benefit from practice in most areas of the test. In order to most effectively figure out where to direct your efforts, we recommend taking a diagnostic practice test as a next step. You’ll probably get the most out of it if you do so with expert guidance.</div>
              }
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    const { isFetching, match, isAuthenticated } = this.props;

    if (!match.params.id) {
      return (
        <div className={styles.loading}>
          No test results found
        </div>
      );
    }
    if (isFetching) {
      return (
        <div className={styles.loading}>
          Results loading...
        </div>
      );
    }
    if (!isAuthenticated) {
      return (
        <div className={styles.loading}>
          Authenticating...
        </div>
      );
    }

    return (
      <div className={styles.testResults}>
        <div className={styles.mainContainer}>
          <div className={styles.header}>
            <img src={Images.logoSlyAndText} alt='testive'></img>
          </div>
          {this.renderTestInfo()}
          {this.renderHeader()}
          {this.renderData()}
          <div className={styles.footer}>
            We&#39;ll email you a summary of these results. Are you ready to start preparing for the {this.props.favoredTest}? <a href="https://www.testive.com/schedule-a-call/">Schedule a free consultation with a Testive prep expert now.</a>
          </div>
        </div>
      </div>
    );
  }
}

TestResults.propTypes = {
  isFetching: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  loadTest: PropTypes.func.isRequired,
  verifyUser: PropTypes.func.isRequired,
  match: PropTypes.object,
  score: PropTypes.object,
  favoredTest: PropTypes.string,
  otherTest: PropTypes.string,
  stronglyFavored: PropTypes.bool.isRequired,
  userEmail: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  userUUID: PropTypes.string,
  history: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    isFetching: state.results.isFetching,
    isAuthenticated: state.user.isAuthenticated,
    score: state.results.score,
    favoredTest: state.results.favoredTest,
    otherTest: state.results.otherTest,
    stronglyFavored: state.results.stronglyFavored,
    displayName: state.user.displayName,
    userEmail: state.user.email,
    userUUID: state.user.userUUID,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadTest: (user_uuid) => dispatch(loadTest(user_uuid)),
    verifyUser: request => dispatch(verifyUser(request))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestResults);
