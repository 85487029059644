export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const USER_VERIFY_REQUEST = 'USER_VERIFY_REQUEST';
export const USER_VERIFY_SUCCESS = 'USER_VERIFY_SUCCESS'; 
export const USER_VERIFY_FAILURE = 'USER_VERIFY_FAILURE';

export const UPDATE_SESSION_STATE = 'UPDATE_SESSION_STATE';
export const START_SESSION = 'START_SESSION';
export const PAUSE_SESSION = 'PAUSE_SESSION';
export const RESUME_SESSION = 'RESUME_SESSION';
export const COMPLETE_TEST_SECTION = 'COMPLETE_TEST_SECTION';
export const CHOOSE_ANSWER = 'CHOOSE_ANSWER';
export const SET_EXTENDED_TIME = 'SET_EXTENDED_TIME';
export const TOGGLE_INSTRUCTION_MODAL = 'TOGGLE_INSTRUCTION_MODAL';
export const GRADE_TEST = 'GRADE_TEST';
export const TEST_ANALYTICS = 'TEST_ANALYTICS';

export const SAVE_TEST_REQUEST = 'SAVE_TEST_REQUEST';
export const SAVE_TEST_SUCCESS = 'SAVE_TEST_SUCCESS';
export const SAVE_TEST_FAILURE = 'SAVE_TEST_FAILURE';

export const LOAD_TEST_REQUEST = 'LOAD_TEST_REQUEST';
export const LOAD_TEST_SUCCESS = 'LOAD_TEST_SUCCESS';
export const LOAD_TEST_FAILURE = 'LOAD_TEST_FAILURE';