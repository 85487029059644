import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '../Button';
import test from '../../constants/test';
import AppTitle from '../AppTitle';
import { startSession, setExtendedTime } from '../../actions';

import styles from './WelcomeScreen.module.scss';

class WelcomeScreen extends React.Component {

  startTest = () => {
    this.props.startSession(this.props.userEmail);
    this.props.history.replace('/test');
  }

  setExtendedTime(event) {
    this.props.setExtendedTime(event.target.checked);
  }

  render() {
    const { extendedTime, testStartDateStamp } = this.props;
    const testStarted = testStartDateStamp ? true : false;
    return (
      <div className={styles.welcomeScreen}>
        <div className={styles.mainContainer}>
          <AppTitle />
          <div className={styles.instructions}>
            <div>
            This assessment will determine which college admissions test is right for you in 30 minutes of multiple-choice questions.
            After completing the assessment you&#39;ll receive a recommended test and the underlying reasons based on your answers and pacing.
            </div> 
            <div className={styles.list}>
              <div className={styles.listTitle}>Before you start, make sure you have…</div>
              <ul>
                <li>30 uninterrupted minutes (60 if you need extended time)</li>
                <li>A pencil and scratch paper</li>
                <li>A calculator <span>(only for use on the Math with Calculator section)</span></li>
                <li>A positive attitude...you can do it!</li>
              </ul>
            </div>
          </div>
          <label className={styles.checkContainer} htmlFor='extended'>
            <input
              className={styles.checkbox}
              type='checkbox'
              id='extended'
              checked={extendedTime}
              disabled={testStarted}
              onChange={this.setExtendedTime.bind(this)}/>
          Check this box if you will be given extended time on the official exam.
          </label>
          <div className={styles.buttonContainer}>
            <Button 
              onClick={this.startTest}
              ctaHighlight={false}>
                Let's get started!
            </Button>
          </div>
        
        </div>
      </div>
    );
  }
}

WelcomeScreen.propTypes = {
  history: PropTypes.object,
  test: PropTypes.array,
  currentStepIndex: PropTypes.number,
  userEmail: PropTypes.string,
  currentTimer: PropTypes.number,
  startSession: PropTypes.func,
  extendedTime: PropTypes.bool,
  testStartDateStamp: PropTypes.instanceOf(Date),
  setExtendedTime: PropTypes.func
};

WelcomeScreen.defaultProps = {
  test: test
};

function mapStateToProps(state) {
  return {
    currentStepIndex: state.testsession.currentStepIndex,
    currentTimer: state.testsession.timeSaved,
    userEmail: state.user.email,
    extendedTime: state.testsession.extendedTime,
    testStartDateStamp: state.testsession.testStartDateStamp
  };
}

function mapDispatchToProps(dispatch) {
  return {
    startSession: userEmail => dispatch(startSession(userEmail)),
    setExtendedTime: (value) => dispatch(setExtendedTime(value))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeScreen);