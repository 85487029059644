import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import EnsureLoggedIn from './components/EnsureLoggedIn';
import VerifyUser from './components/VerifyUser';
import WelcomeScreen from './components/WelcomeScreen';
import TestManager from './components/TestManager';
import TestResults from './components/TestResults';
import TestComplete from './components/TestComplete';

import styles from './app.module.scss';

class App extends Component {
  render() {
    
    return (
      <div className={styles.appMain}>     
        <BrowserRouter>
          <Switch>
            <Route exact path='/' component={VerifyUser} />
            <Route exact path='/login' component={VerifyUser} />
            <Route exact path='/login/:id' component={VerifyUser} />
            <Route exact path='/welcome' component={EnsureLoggedIn(WelcomeScreen)} />
            <Route exact path='/test' component={EnsureLoggedIn(TestManager)} />
            <Route exact path='/complete' component={EnsureLoggedIn(TestComplete)} />
            <Route exact path='/results/:id?' component={TestResults} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
