import React from 'react';
import KatexText from './../components/KatexText';
import styles from './../components/Passage/Passage.module.scss';
import Images from './../assets/Images';

const passageBody = 
    <div className={styles.passageBody}>
      <div className={styles.para}>
        <KatexText 
          text={'The threshold frequency, [math]ft[/math], of a metal refers to the frequency of light that will cause an electron to dislodge from that metal. When a photon’s frequency [math]f[/math] is greater than [math]ft[/math], the photon’s excess energy is transferred to the electron as kinetic energy, which ejects the electron from the metal. [math]Kmax[/math] is the maximum kinetic energy an ejected electron can have for a given [math]f[/math].'}>
        </KatexText>
      </div>
      <div className={styles.para}>
        <KatexText 
          text={'Table 1 displays[math]ft[/math] for the metals potassium (K), lead (Pb), mercury (Hg), and beryllium (Be)'}/>
      </div>
      <div className={styles.table}>
        <span className={styles.tableTitle}>Table 1</span>
        <table> 
          <tbody>
            <tr><th>Metal</th><th>Ft(10^14 Hz)</th></tr> 
            <tr><td>K</td><td>5.37</td></tr> 
            <tr><td>Pb</td><td>9.9</td></tr>  
            <tr><td>Hg</td><td>10.8</td></tr> 
            <tr><td>Be</td><td>12.0</td></tr>  
          </tbody>
        </table> 
      </div>
      <div className={styles.passageFigure}>
        <KatexText 
          text={'Figure 1 is a graph of[math]Kmax[/math] versus[math]f[/math] for each of the 4 metals.'}/>
        <img src={Images.scienceFigure1} width={350} alt='figure 1'></img>
      </div>
      <div className={styles.passageFigure}>
        <KatexText 
          text={'Figure 2 is a graph of a photon’s energy versus its [math]f[/math].'}/>
        <img src={Images.scienceFigure2} width={350} alt='figure 2'></img>
      </div>
    </div>;

export default passageBody;
