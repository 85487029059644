import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '../Button';
import { gradeTest, saveTest } from '../../actions';

import styles from './TestComplete.module.scss';

class TestComplete extends React.Component {

  componentDidMount() {
    this.props.gradeTest(this.props.testAnswers);
  }

  componentDidUpdate(prevProps) {
    if(Object.keys(prevProps.score).length === 0 && Object.keys(this.props.score).length > 0) {
      this.props.saveTest(this.props.userUUID, JSON.stringify(this.props.testAnswers), this.props.favor);
    }
  }

  viewReport = () => {
    this.props.history.push('results/' + this.props.userUUID);
  }

  render() {
    const { score, favor, otherTest, stronglyFavored } = this.props;

    if(Object.keys(score).length === 0 ) {
      return (
        <div className={styles.loading}>
          Results loading...
        </div>
      );
    }

    return (
      <div className={styles.results}>
        <div className={styles.header}>Whew! You finished! How’d you do&#63;</div>
        {
          stronglyFavored ?
            <div className={styles.subHeader}>
              Based on your performance, the <span>{favor}</span> is the right test for you. 
            </div>
            :
            <div className={styles.subHeader}>
              Based on your performance on this assessment, we believe that the <span>{favor}</span> is 
              the right test for you, but you might still benefit from holding onto the {otherTest} as 
              an option. We’d suggest that you start with the <span>{favor}</span> and consider the {otherTest} as 
              a backup plan.
            </div>
        }
        <div className={styles.button}>
          <Button
            onClick={this.viewReport}
            ctaHighlight={true}>
            RESULTS ANALYSIS
          </Button>
        </div>
      </div>
    );
  }
}

TestComplete.propTypes = {
  gradeTest: PropTypes.func,
  saveTest: PropTypes.func,
  testAnswers: PropTypes.object,
  score: PropTypes.object,
  favoredTest: PropTypes.string,
  otherTest: PropTypes.string,
  stronglyFavored: PropTypes.bool,
  userEmail: PropTypes.string,
  userUUID: PropTypes.string,
  history: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    testAnswers: state.testsession.testAnswers,
    score: state.results.score,
    favor: state.results.favoredTest,
    otherTest: state.results.otherTest,
    stronglyFavored: state.results.stronglyFavored,
    userEmail: state.user.email,
    userUUID: state.user.userUUID,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    gradeTest: (answers) => dispatch(gradeTest(answers)),
    saveTest: (user_uuid, answers, favor) => dispatch(saveTest(user_uuid, answers, favor)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestComplete);