import React from 'react';
import PropTypes from 'prop-types';

import KatexText from './../KatexText';
import Answer from './../Answer';
import DetectDesktopOrMobile from '../DetectDesktopOrMobile';

import styles from './Question.module.scss';
class Question extends React.Component {
  constructor(props){
    super(props);
    
    this.state = {
      selectedAnswer: props.selectedAnswer
    };
  
  }

  answerClicked = (answerID) => {
    this.setState({selectedAnswer: answerID})
    this.props.chooseAnswer(this.props.questionID, answerID);
  }
  
  render() {
    const { questionNumber, questionBody, answerOptions } = this.props;
    const { selectedAnswer } = this.state;
    const AnswerResponsive = DetectDesktopOrMobile(Answer);
    const answerChoices = answerOptions.map((answerOption, index) => 
    {
      return(<AnswerResponsive
        key={index}
        answerID={index}
        answerText={answerOption}
        answerClicked={this.answerClicked}
        selected={index===selectedAnswer}>
      </AnswerResponsive>);
    }); 

    return (
      <div className={styles.question}>
        <div className={styles.questionBody}>
          <div className={styles.questionNumber}>{questionNumber}.</div>
          <KatexText text={questionBody} />
        </div>
        <div className={styles.answerSet}>
          {answerChoices}
        </div>
      </div>
    );
  }
}

Question.propTypes = {
  questionID: PropTypes.number.isRequired,
  questionNumber: PropTypes.number.isRequired,
  questionBody: PropTypes.node.isRequired,
  answerOptions: PropTypes.array.isRequired,
  chooseAnswer: PropTypes.func.isRequired,
  selectedAnswer: PropTypes.number,
  darkMode: PropTypes.bool.isRequired
};

Question.defaultProps = {
  darkMode: true
};

export default Question;