import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './VerifyUser.module.scss';
import { verifyUser } from './../../actions';
import ErrorMessage from './../ErrorMessage';
import Button from '../Button';
import AppTitle from './../AppTitle';

class VerifyUser extends React.Component {
  handleRegisterClick = () => {
    window.location.href = 'https://www.testive.com/selector';
  }

  handleStartClick = () => {
    this.props.history.replace('/welcome');
  }

  componentDidUpdate()  {
    const { isValidUserID } = this.props;
    if (isValidUserID) {
      this.props.history.replace('/welcome');
    }
  }

  componentDidMount() {
    // if there is a current user logged in then skip this
    // else validate this uuid and send to onboard 
    const { isValidUserID } = this.props;
    if (isValidUserID) {
      this.props.history.replace('/welcome');
    }
    else {
      this.props.verifyUser({
        user_uuid: this.props.match.params.id
      });
    }
  }

  renderValidUser() {
    const { displayName}  = this.props;
    return (
      <div className={styles.verifyUser}>
        <div className={styles.mainContainer}>
          <AppTitle></AppTitle>
          <div className={styles.formContainer}>
            <div className={styles.info}>Welcome</div> 
            <div className={styles.info}>{displayName}</div>
            <Button onClick={this.handleStartClick}>start</Button>
          </div>
        </div>
      </div>
    );
  }

  renderInvalidUser(error) {
    return (
      <div className={styles.verifyUser}>
        <div className={styles.mainContainer}>
          <AppTitle />
   
          <div className={styles.formContainer}>
            <div className={styles.info}>We could not locate your account</div>
            <ErrorMessage errors={error}></ErrorMessage>
            <div className={styles.helpInfo}>If you need help email</div>
            <a href={"mailto:happy@testive.com"}>happy@testive.com</a>
          </div>
        </div>
      </div>
    );
  }
  renderNoUser() {
    return (
      <div className={styles.verifyUser}>
        <div className={styles.mainContainer}>
          <AppTitle />
          <div className={styles.formContainer}>
          <div className={styles.info}>We could not locate your account</div>
            <Button onClick={this.handleRegisterClick}>
              Register Here
            </Button>
          </div>
        </div>
      </div>
    );
  }


  render(){
    const { isValidatingUserID, isValidUserID, error} = this.props;
    if (isValidatingUserID)
      return (<div className={styles.verifyUser}>authenticating...</div>);
    else if (!isValidUserID)
      if (!this.props.match.params.id)
        return this.renderNoUser();
      else
        return this.renderInvalidUser(error);
    else 
      return null;
      // return this.renderValidUser();
  }

}

VerifyUser.propTypes = {
  verifyUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isValidatingUserID: PropTypes.bool.isRequired,
  isValidUserID: PropTypes.bool.isRequired,
  error: PropTypes.string,
  displayName: PropTypes.string
};

function mapStateToProps(state) {
  return {
    isValidatingUserID: state.user.isAuthenticating,
    isValidUserID: state.user.isAuthenticated,
    error: state.user.userVerifyError,
    displayName: state.user.displayName
  };
}

function mapDispatchToProps(dispatch) {
  return {
    verifyUser: request => dispatch(verifyUser(request))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyUser);