import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TestSession from '../TestSession';
import TestBreak from '../TestBreak';

class TestManager extends React.Component {

  render() {
    const currentStep = this.props.currentStep;
    if(currentStep.type === 'break') {
      return <TestBreak />;
    }
    else {
      return <TestSession section={currentStep} history={this.props.history}/>;
    }
  }
}


TestManager.propTypes = {
  currentStep: PropTypes.object,
  history: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    currentStep: state.testsession.currentStep
  };
}

export default connect(mapStateToProps)(TestManager);