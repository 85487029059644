import React, { Component } from 'react';
import styles from './AppTitle.module.scss';
import Images from './../../assets/Images';

class AppTitle extends Component {

  render() {
    return (
      <div className={styles.appTitle}>
        <img src={Images.appTitleLogo} alt='Test Selector'></img>
      </div>);
  };
}

export default AppTitle; 
