import React from 'react';
import PropTypes from 'prop-types';
import PassageQuestionSet from './../PassageQuestionSet';
import styles from './PassageTest.module.scss';


class PassageTest extends React.Component {

  chooseAnswer = (questionID, answerID) => {
    this.props.chooseAnswer(this.props.section.sectionIndex, questionID, answerID);
  }

  currentQuestionChange = (questionID) => {
    this.props.currentQuestionChange(questionID);
  }

  render() {
    // Eventually we will have more than one passage per test
    const { 
      section, 
      currentQuestion, 
      completedQuestions,
      completeSection } = this.props;

    return (
      <div className={styles.passageTest}>
      <PassageQuestionSet 
        chooseAnswer={this.chooseAnswer}
        currentQuestionChange={this.currentQuestionChange}
        passage={section.passage}
        questions={section.questions}
        currentQuestion={currentQuestion}
        completedQuestions={completedQuestions}
        completeSection={completeSection}/>
      </div>
    );

  }
}

PassageTest.propTypes = {
  section: PropTypes.object,
  chooseAnswer: PropTypes.func,
  currentQuestionChange: PropTypes.func,
  currentQuestion: PropTypes.number,
  completedQuestions: PropTypes.object.isRequired,
  completeSection: PropTypes.func.isRequired
};

PassageTest.defaultProps = {
  completedQuestions: {}
};

export default PassageTest;