import React from 'react';
import PropTypes from 'prop-types';

import styles from './Passage.module.scss';
class Passage extends React.Component {

  render() {
    const { passage } = this.props;
    return (
      <div className={styles.passage}>
        {passage}       
      </div>
    );
  }
}

Passage.propTypes = {
  passage: PropTypes.object.isRequired,
};

export default Passage;