import React from 'react';
import PropTypes from 'prop-types';
import Passage from './../Passage';
import QuestionSet from './../QuestionSet';
import DetectDesktopOrMobile from './../DetectDesktopOrMobile';
import styles from './PassageQuestionSet.module.scss';

class PassageQuestionSet extends React.Component {

  render() {
    const { 
      passage, 
      questions, 
      chooseAnswer, 
      currentQuestion, 
      currentQuestionChange,
      completedQuestions,
      completeSection } = this.props;

    const QuestionSetResponsive = DetectDesktopOrMobile(QuestionSet);

    return (
      <div className={styles.passageQuestionSet}>
        <div className={styles.passageViewer}>
          <Passage passage={passage} />
        </div>
        <div className={styles.passageQuestions}>
          <QuestionSetResponsive 
            currentQuestionChange={currentQuestionChange}
            chooseAnswer={chooseAnswer} 
            questions={questions}
            currentQuestion={currentQuestion}
            completedQuestions={completedQuestions}
            completeSection={completeSection}/>
        </div>
      </div>
    );
  }
}

PassageQuestionSet.propTypes = {
  passage: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  chooseAnswer: PropTypes.func.isRequired,
  currentQuestionChange: PropTypes.func.isRequired,
  completedQuestions: PropTypes.object.isRequired,
  completeSection: PropTypes.func.isRequired
};

export default PassageQuestionSet;