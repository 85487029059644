import * as constants from '../constants/actions';
import test from '../constants/test';

let testAnswers = JSON.parse(localStorage.getItem('testAnswers'));
let currentStepIndex = parseInt(localStorage.getItem('currentStepIndex'));
currentStepIndex = currentStepIndex ? currentStepIndex : 0;
let currentStep = test[currentStepIndex];
let timeSaved = parseInt(localStorage.getItem('timeSaved'));
let timerStart = localStorage.getItem('timerStart');
timerStart = timerStart ? new Date(timerStart) : undefined;
let extendedTime = JSON.parse(localStorage.getItem('extendedTime'));
let showInstructionModal = JSON.parse(localStorage.getItem('showInstructionModal'));
let testStartDateStamp = localStorage.getItem('testStartDateStamp');
testStartDateStamp = testStartDateStamp ? new Date(testStartDateStamp) : undefined;
let currentQuestion = parseInt(localStorage.getItem('currentQuestion'));

const initialState = {
  currentStepIndex: currentStepIndex,
  currentStep: currentStep,
  testStartDateStamp: testStartDateStamp,
  extendedTime: extendedTime ? extendedTime : false,
  showInstructionModal: showInstructionModal != null ? showInstructionModal : true,
  timeSaved: timeSaved,
  currentQuestion: currentQuestion ? currentQuestion : 0,
  timerStart: timerStart,
  testAnswers: testAnswers ? testAnswers : {}, // format {ACT_1_MATH: {1: 'A', 2: 'F', 3: 'None', ...}, ACT_1_READING: {...}, ...}
  error: undefined,
};
export default function testsession(state = initialState, action) {
  switch (action.type) {
      
    case constants.LOGOUT: 
      return Object.assign({}, state, initialState);

    case constants.SET_EXTENDED_TIME:
      localStorage.setItem('extendedTime', action.value);
      return Object.assign({}, state, {
        extendedTime: action.value
      });

    case constants.TOGGLE_INSTRUCTION_MODAL:
      var show = !state.showInstructionModal;
      localStorage.setItem('showInstructionModal', show);
      return Object.assign({}, state, {
        showInstructionModal: show
      });

    case constants.START_SESSION:
      var dateStampNow = new Date();
      localStorage.setItem('testStartDateStamp', dateStampNow);
      
      return Object.assign({}, state, {
        currentStepIndex: 0,
        currentStep: test[0],
        testSessionStatus: 'ACTIVE',
        testStartDateStamp: dateStampNow,
        showInstructionModal: true,
      });

    case constants.PAUSE_SESSION:
      localStorage.setItem('timeSaved', action.timeSaved);
      
      return Object.assign({}, state, {
        testSessionStatus: 'PAUSED',
        timeSaved: action.timeSaved,
      });

    case constants.UPDATE_SESSION_STATE:
      localStorage.setItem('timeSaved', action.time);
      localStorage.setItem('currentQuestion', action.currentQuestion);
      return Object.assign({}, state, {
        timeSaved: action.time,
        currentQuestion: action.currentQuestion
      });

    case constants.RESUME_SESSION:
      // this should never happen but it did so safety first
      if (state.currentStepIndex >= test.length)
      {
        localStorage.setItem('currentStepIndex', 0);
        return Object.assign({}, state, {
          testSessionStatus: 'COMPLETED',
          currentStepIndex: 0,
          currentStep: test[0],
        });
      }
      else {
        return Object.assign({}, state, {
          testSessionStatus: 'ACTIVE',
        });
      }

    case constants.COMPLETE_TEST_SECTION:
      localStorage.setItem('currentQuestion', 0);
      var answers = state.testAnswers;
      if(action.sectionTime) {
        if(!answers[state.currentStep.sectionIndex]) {
          answers[state.currentStep.sectionIndex] = {};
        }
        answers[state.currentStep.sectionIndex]['timeLeft'] = action.sectionTime;
      }

      // if more steps go to the next one, otherwise leave it be
      if (state.currentStepIndex+1 < test.length) {

        var nextStepIndex = state.currentStepIndex + 1;
        localStorage.setItem('currentStepIndex', nextStepIndex);
        localStorage.setItem('testAnswers', JSON.stringify(answers));
        localStorage.removeItem('timeSaved');
        localStorage.setItem('timerStart', new Date());
        localStorage.setItem('showInstructionModal', true);


        return Object.assign({}, state, {
          testAnswers: answers,
          showInstructionModal: true,
          timeSaved: null,
          timerStart: new Date(),
          currentStepIndex: nextStepIndex,
          currentStep: test[nextStepIndex],
          currentQuestion: 0
        });
      }
      else {
        localStorage.setItem('testAnswers', JSON.stringify(answers));
        return Object.assign({}, state, {
          testAnswers: answers
        });
      }
      
    case constants.CHOOSE_ANSWER:
      var testAnswers = state.testAnswers;
    
      if (!testAnswers[action.testSectionId]) {
        testAnswers[action.testSectionId] = {};
      }
     
      testAnswers[action.testSectionId][String(action.question)] = action.answer;

      localStorage.setItem('testAnswers', JSON.stringify(testAnswers));
      localStorage.setItem('timeSaved', action.timeSaved);
      return Object.assign({}, state, {
        testAnswers: testAnswers,
        timeSaved: action.timeSaved,
        resultsLoading: false,
        error: undefined,
      });

    // case constants.LOAD_TEST_SUCCESS:
    //   return Object.assign({}, state, {
    //     testAnswers: action.answers
    //   });

    default:
      return state;
  }
}