import React from 'react';
import styles from './../components/Question/Question.module.scss';

const mathConstants = {
  q53116b: (<div>A cylindrical can of soda (pictured) has a height of 5 inches and a base with a diameter of 2 inches. <img src="https://d14qx55ky13qwa.cloudfront.net/questions/act/53116.png" alt='math figure' /><br/>What is the surface area of the outside of the entire can of soda in square inches (assume faces are flat)?</div>),
  q53111b: (
    <div>The table below shows firefighter salaries in Marigold County over the past few decades, in dollars.
      <div className={styles.table}>
        <table><thead><tr><th>Year</th><th>Average Annual Salary</th></tr></thead>
        <tbody>
          <tr><td>1990</td><td>33,000</td></tr>
          <tr><td>1995</td><td>35,000</td></tr>
          <tr><td>2000</td><td>38,000</td></tr>
          <tr><td>2005</td><td>44,000</td></tr>
          <tr><td>2010</td><td>49,000</td></tr>
          <tr><td>2015</td><td>56,000</td></tr>
        </tbody>
        </table>
      </div>
      Based on this table, what was the average yearly pay increase, in dollars, for firefighters in Marigold County from 1990 to 2015?
    </div>),
  q53112b: (
    <div>The table below shows firefighter salaries in Marigold County over the past few decades, in dollars.
      <div className={styles.table}>
        <table><thead><tr><th>Year</th><th>Average Annual Salary</th></tr></thead>
        <tbody>
          <tr><td>1990</td><td>33,000</td></tr>
          <tr><td>1995</td><td>35,000</td></tr>
          <tr><td>2000</td><td>38,000</td></tr>
          <tr><td>2005</td><td>44,000</td></tr>
          <tr><td>2010</td><td>49,000</td></tr>
          <tr><td>2015</td><td>56,000</td></tr>
        </tbody>
        </table>
      </div>
      Over which 5-year time span did firefighters in Marigold County see the highest percentage growth in their salary?
      </div>)
}

export default mathConstants;